import imgAutomatic from '@assets/images/img-automatic.png'
import imgAdministrative from '@assets/images/img-save-time-administrative.png'
import imgTsqPlanning from '@assets/images/img-tailor-intelligent-planning.png'
import imgPlanning from '@assets/images/img-uptodate-planning.png'

const content = [
    {
        title: 'Planning intelligent',
        description:
            "Optimisez vos plannings grâce à la suggestion automatique des roulements en fonction de vos besoins. L'intelligence artificielle de Staffea rend votre gestion de planning bien plus facile.",
        image: imgTsqPlanning,
        alt: "Staffea, application web écran d'automatisation des plannings",
        footer: {
            text: 'Découvrez un ',
            link: 'exemple de planning en ehpad.',
            href: '#',
        },
    },
    {
        title: 'Gain de temps administratif',
        description:
            "Ne perdez plus de temps dans des tâches chronophages. Plannings, échanges d'horaires, absences, congés, arrêts maladies et contrats de travail sont automatiquement mis à jour sur la solution Staffea.",
        image: imgAdministrative,
        alt: 'Staffea, application web écran des demandes de congés',
        footer: {
            text: 'Comprendre la ',
            link: 'gestion du personnel en ehpad.',
            href: '#',
        },
    },
    {
        title: 'Planning à jour',
        description:
            'Vos plannings sont toujours à jour, peu importe le nombre de modifications apportées. Le système de pointage vous permet de comptabiliser les heures réellement effectuées et de faciliter le calcul des éléments variables de paie.',
        image: imgPlanning,
        alt: 'Staffea, application web écran de gestion du planning des managers',
    },
    {
        title: 'Maintenance automatique',
        description:
            "Aucune maintenance de notre solution n'est requise ! Les mises à jour du logiciel de planning RH sont incluses dans votre abonnement. Toutes vos données sont sauvegardées automatiquement.",
        image: imgAutomatic,
        alt: "Staffea, application web écran d'accueil avec notifications de mise à jour",
    },
]

export default content
